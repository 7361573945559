import { Controller } from "@hotwired/stimulus"
import { load as loadPlayable } from "../player/playable"

export default class extends Controller {
  static values = {
    attributes: Object
  }

  async play(event, indexToPlay) {
    event.stopPropagation()

    if (event.target.tagName === "A" || (event.target.tagName === "INPUT" && event.target.type === "submit")) {
      return
    }

    try {
      const playable = await loadPlayable(this.attributesValue["playable"])
      if (indexToPlay != null) playable._index = indexToPlay
      this.dispatch("play", { detail: playable })
    } catch {
      console.log("Could not play")
    }
  }

  playOrResume(e) {
    if (this.alreadyPlayingTheRequestedPlaylist()) {
      this.dispatch("resume")
    } else {
      this.play(e)
    }
  }

  playIndex(event) {
    let indexToPlay = Array.prototype.indexOf.call(this.element.parentNode.children, this.element)
    if (this.alreadyPlayingTheRequestedPlaylist()) {
      this.dispatch("skipToTrack", { detail: indexToPlay })
    } else {
      this.play(event, indexToPlay)
    }
  }

  getPlayer() {
    return this.application.getControllerForElementAndIdentifier(document.querySelector('#player'), 'player')
  }

  alreadyPlayingTheRequestedPlaylist() {
    let player = this.getPlayer()
    return player.playableList.current && player.playableList.current.path == this.attributesValue["playable"].path
  }

}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "menu", "label" ]
  static classes = [ "show", "hidden" ]

  connect() {
    this.hideMenu()
  }

  toggle(event) {
    event.stopPropagation()

    if (this.menuTarget.classList.contains(this.showClass)) {
      this.hideMenu()
    } else {
      this.closeAllDropdowns()
      this.showMenu(event)
    }
  }

  hide(event) {
    if (!this.element.contains(event.target)) {
      this.hideMenu()
    }
  }

  select(event) {
    const selectedLabel = event.currentTarget.dataset.dropdownLabel
    const targetId = event.currentTarget.dataset.targetId

    if (this.hasLabelTarget) {
      this.labelTarget.textContent = selectedLabel
    }

    this.hideMenu()
  }

  stopPropagation(event) {
    event.stopPropagation()
  }

  hideMenu() {
    this.menuTarget.classList.remove(this.showClass)
    this.menuTarget.classList.add(this.hiddenClass)
  }

  hideMenuInstance(event) {
    if (event.detail == this.element) {
      this.hideMenu()
    }
  }

  showMenu(event) {
    this.menuTarget.classList.add(this.showClass)
    this.menuTarget.classList.remove(this.hiddenClass)
    this.positionDropdown(event)
  }

  closeAllDropdowns() {
    document.querySelectorAll(`[data-controller*="dropdown"] .${this.showClass}`).forEach(menu => {
      menu.classList.remove(this.showClass)
    })
  }

  positionDropdown(event) {
    const button = event.target.closest('button')
    const menu = this.menuTarget
    const menuWidth = menu.getBoundingClientRect().width
    const rect = button.getBoundingClientRect()
    const spaceBelow = window.innerHeight - rect.bottom
    const spaceRight = window.innerWidth - rect.right
    const isSmallestScreen = window.innerWidth < 640

    if (spaceBelow < menu.offsetHeight && rect.top > menu.offsetHeight && !button.closest('.overflow-x-auto')) {
      menu.style.top = 'auto'
      menu.style.bottom = '100%'
      menu.style.marginTop = '0'
      menu.style.marginBottom = '0.25rem'
    } else {
      menu.style.top = isSmallestScreen ? '' : '100%'
      menu.style.bottom = 'auto'
      menu.style.marginTop = isSmallestScreen ? '' : '0.25rem'
      menu.style.marginBottom = '0'
    }

    if (this.element.closest('header') !== null) {
      // we use a full width approach for header dropdowns, therefore we skip the horizontal positioning here
      return
    }

    if (spaceRight < menuWidth) {
      menu.style.left = 'auto';
      menu.style.right = '0';
      menu.style.marginLeft = '0';
      menu.style.marginRight = '0.25rem';
    } else {
      menu.style.left = '';
      menu.style.right = '';
      menu.style.marginLeft = '';
      menu.style.marginRight = '';
    }
  }
}
